import React, {useEffect} from "react"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {graphql, Link} from "gatsby"
import Layout from "../components/layout";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ArrowRightLong from '@material-ui/icons/ArrowRightAlt'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import moment from 'moment';
import SEO from "../components/seo";
import BreadCrumbs from "../components/bread-crumbs";
import './article-details.scss';
import "../pages/popup-cta.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { isValidContentfulImage } from "../common/utils/CommonUtils"


export default (props) => {

    useEffect(() => {
        const element = document.getElementById('main-Heading');
        window.scrollTo({
            behavior: element ? "smooth" : "auto",
            top: element ? element.offsetTop : 0
        });
    },);

    const {data, pageContext} = props;
    let documentContent;
    let allEducationalOrder;
    let updatedEducationOrder;

    allEducationalOrder = data.contentfulCategory.topics.filter((items) => {
        return items.name === pageContext.topic.name;
    });
    updatedEducationOrder = allEducationalOrder[0].educationOrder.filter(
        (items) => {
            return items.publishOnWebsite
        });
    data.contentfulCategory.topics.forEach((items) => {
        if (!documentContent || documentContent.length === 0) {
            if (items.educationOrder) {
                documentContent = items.educationOrder.filter((item) => {
                    return item.slug === pageContext.articleSlug;
                })
            }
        }
    });
    const authorImage = documentContent[0].authorImage
        ? documentContent[0].authorImage.file.url : '';
    const formattedDate = moment(documentContent[0].createdAt).format(
        'DD-MMM-YY HH:mm:ss');
    const prev = pageContext.prev
        ? {
            url: `/${pageContext.category?.slug}/${pageContext.topic?.slug}/${pageContext.prev.slug}`,
            title: pageContext.prev.title.title
        }
        : null;
    const next = pageContext.next
        ? {
            url: `/${pageContext.category?.slug}/${pageContext.topic?.slug}/${pageContext.next.slug}`,
            title: pageContext.next.title.title
        }
        : null;
    const assetMap = new Map();
    // console.log({documentContent:documentContent[0]})
    for (const asset of documentContent[0].content.references) {
        assetMap.set(asset.contentful_id, asset);
    }
    // console.log({assetMap});
    if (!pageContext.category) {

    }
    let options = {
        renderNode: {
            'embedded-asset-block': (node) => {
                console.log({node});
                const assetId = node.data.target.sys.id;
                const asset = assetMap.get(assetId);
                // const src = node.data.target.fields.file['en-US'].url;
                return <GatsbyImage image={getImage(asset)} className="img-fluid" alt="mainimg"/>
                // return null;
            }
        }
    };

    const breadCrumbsList  = [
        {
            title: "Home",
            link: "/",
        },
        {
            title: "Learning Library",
            link: "/learning-library",
        },
        {
            title: pageContext.category?.name,
            link: `/${pageContext.category?.slug}`,
        },
        {
            title: pageContext.topic?.name,
            link: `/${pageContext.category?.slug}/${pageContext.topic?.slug}`,
        },

    ];

    const metaDescription = documentContent[0].metaDescription && documentContent[0].metaDescription.metaDescription;
    const metaTitle = (documentContent[0].metaTitle && documentContent[0].metaTitle.metaTitle) || documentContent[0].title.title;
    return (

        <Layout location={props.location}>
            <SEO title={metaTitle} description={metaDescription}/>
            {/*<div className="bread-crumbs">
                <div className="crumbs-inner">
                    <div className="crumbs-side">
                        <a className="crumbs-text" href={'/'}>
                            Home
                        </a>
                        <ArrowRightIcon/>
                        <a href={'/learning-library/'} className="crumbs-text">
                            Learning Library
                        </a>
                        <ArrowRightIcon/>
                        <a href={`/${pageContext.category?.slug}`}
                           className="crumbs-text">{pageContext.category?.name}</a>
                        <ArrowRightIcon/>
                        <a href={`/${pageContext.category?.slug}/${pageContext.topic?.slug}`}
                           className="crumbs-text"> {pageContext.topic?.name}</a>
                        <ArrowRightIcon/>
                        <p className="crumbs-text-black">{documentContent[0].title.title}</p>
                    </div>
                </div>
            </div>*/}
            <BreadCrumbs currentPage={documentContent[0].title.title} breadCrumbsList={breadCrumbsList}/>
            <div className="new-article-body">
                <div className="left-side-box">
                    <div className="left-icon-box">
                        <img src={require("../assets/images/help_FnF.svg")}
                             alt="Icon"/>
                    </div>
                    <FormControl variant="filled" className="drugs-dropdown">
                        <InputLabel
                            id="service-label">{pageContext.topic.name}</InputLabel>
                        <Select
                            labelId="service-label"
                            id="service-select"
                            value={pageContext.topic.name}
                            label="Select Service"
                        >{
                            data.contentfulCategory.topics.filter(topic => {
                                return topic.publishOnWebsite === true
                            }).map((item) => {
                                return (
                                    <MenuItem className="data-dropDown"
                                              value={item.slug}>
                                        <Link className="topic-link"
                                              to={`/${pageContext.category?.slug}/${pageContext.topic.slug}/`}>{item.name}</Link>
                                    </MenuItem>
                                )

                            })
                        }
                        </Select>
                    </FormControl>
                    <div className="drop-down-main">
                        {

                            updatedEducationOrder.map((item) => {
                                return (
                                    <ul className="left-article-list">
                                        <li>
                                            <Link className="topic-click"
                                                  to={`/${pageContext.category?.slug}/${pageContext.topic?.slug}/${item.slug}/`}>{item.title.title}</Link>
                                        </li>
                                    </ul>
                                )

                            })
                        }
                    </div>
                </div>
                <div className="right-side-box">

                    <h1 className="blackHeading2"
                        id={`main-Heading`}>{documentContent[0].title.title}</h1>

                    <div className="at-author-box">
                        {authorImage && (
                            <img src={authorImage} alt="article-img"/>
                        )}
                        <div className="at-author-text">
                            <p className="main-text">{documentContent[0].authorName
                                ? 'By ' + documentContent[0].authorName + ','
                                : ''} {formattedDate}</p>
                            {documentContent[0].contentLengthduration && (
                                <p className="time-text">{documentContent[0].contentLengthduration} read
                                    time</p>
                            )}
                        </div>
                    </div>
                    {
                        isValidContentfulImage(documentContent[0].titleImage) && (
                            <GatsbyImage
                                image={getImage(documentContent[0].titleImage)}
                                className="new-article-author"
                                // src={`${item.authorImage.file.url}`}
                                alt="Author"/>
                        )
                    }


                    <div className="audio-file" id="audio-player">
                        {documentContent[0].contentAudio !== null ?
                            <AudioPlayer
                                id="audio-player"
                progressUpdateInterval={500}
                loop={false}
                src={documentContent[0].contentAudio.file.url}
              />
              : null}
          </div>

                    {
                        <div className="article-text">
                            <div
                                className="article-parah">  {documentToReactComponents(
                                JSON.parse(documentContent[0].content.raw), options)}</div>
                        </div>
                    }
                </div>
            </div>

            <div className="next-pre-wrapper">
                <div className="next-pre-btns">
                    {prev && (
                        <Link className="pre-box" to={prev.url+'/'}>
                            <ArrowRightLong className="left-arro"/>
                            <div className="next-pre-text">
                                <p className="dark">Previous</p>
                                <p className="light">{prev.title}</p>
                            </div>
                        </Link>
                    )}
                    <div className="article-count">
                        <p>Article {pageContext.current} of {pageContext.total}</p>
                    </div>
                    {next && (
                        <Link className="next-box" to={next.url+'/'}>
                            <div className="next-pre-text">
                                <p className="dark">Next</p>
                                <p className="light">{next.title}</p>
                            </div>
                            <ArrowRightLong/>
                        </Link>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql` 
        query topicsByIdForArticleDetails($slug:String) {
          contentfulCategory(slug: {eq: $slug}) {
            name
            
            topics {
              name
              slug
              publishOnWebsite
              educationOrder {
                slug
                publishOnWebsite
                titleImage {
                  file {
                    url
                  }
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                ... on ContentfulEducationalContent{
                    metaTitle {
                        
                            metaTitle
                        
                    }
                    metaDescription {
                        
                            metaDescription
                        
                    }
                    keywords
                }
                
                title {
                  title
                }
                content {
                  raw
                  references {
                  
                        file {url}
                      contentful_id
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  
                }
                contentAudio {
                  id
                  file {
                    contentType
                    fileName
                    url
                  }
                }
                authorName
                contentLengthduration
                createdAt
                authorImage {
                  file {
                    url
                  }
                }
              }
            }
          }
  }
`;


